import React, { useState, useEffect, useContext } from "react";
import { DataState, DataStateContext } from './dataState';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

export interface StatedComponentProps {
	path?: string;
	dataState?: DataState;
}

export const dataStated = <P extends object>(
	Component: React.ComponentType<P>
): React.FC<P & StatedComponentProps> => ({ dataState, path, ...props }: StatedComponentProps) => {
	const ref = Symbol();
	const handleOnChange = (args: any[]) => {
		if (dataState) {
			if (args.length == 2) {
				dataState.set(path, args[1], { sender: ref });
			} else {
				dataState.set(path, (args[0].currentTarget as any).value, { sender: ref });
			}
		}
	};

	let [value, setValue] = useState('');
	let [help, setHelp] = useState(null as any[] | null);
	let [invalid, setInvalid] = useState(false);

	dataState = useContext(DataStateContext) || dataState;

	useEffect(() => {
		const until = new Subject<void>();

		if (dataState) {
			dataState.pathChange
				.pipe(takeUntil(until), filter(event => event.path == path))
				.subscribe(event => {
					if (event.previousValue != event.currentValue) {
						setValue(event.currentValue);
					}
				});

			dataState.validationReset
				.pipe(takeUntil(until))
				.subscribe(() => {
					setInvalid(false);
					setHelp(null);
				});

			dataState.validationError
				.pipe(takeUntil(until), filter(event => event.path == path))
				.subscribe(error => {
					setInvalid(true);

					if (error.constraints) {
						const errors = error.constraints;
						setHelp(Object.keys(errors)
							.map((key: string) => <span style={{ display: 'block' }} key={key}>{errors[key]}</span>));
					}
				});

			setValue(dataState.get(path) || '');

			return () => {
				until.next();
				until.complete();
			};
		}

	}, []);

	const C = Component as any;
	return <C
		{...props}
		value={value}
		helperText={help}
		error={invalid}
		onChange={(...args: any[]) => handleOnChange(args)}></C>
};
