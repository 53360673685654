import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { DialogContent, Checkbox, FormControlLabel } from '@material-ui/core';

const ConfirmDialog: React.FC<{
	onCancel: () => void,
	onConfirm: (checked: boolean) => void
}> = ({ onCancel, onConfirm }) => {
	const [checked, setChecked] = useState(false);

	return <Dialog open={true as any}
		fullWidth={true}
		maxWidth="sm"
		onClose={onCancel}>
		<DialogTitle>{"Potvrzení akce"}</DialogTitle>
		<DialogContent>
			<FormControlLabel
				control={
					<Checkbox
						onChange={(event, checked) => setChecked(checked)}
					/>
				}
				label="Odeslat informační e-mail zákazníkovi o změně času?"
			/>
		</DialogContent>
		<DialogActions>
			<Button variant="text" onClick={onCancel}>Zrušit</Button>
			<Button variant="contained" color="primary" onClick={() => onConfirm(checked)}>Potvrdit</Button>
		</DialogActions>
	</Dialog>
}

export default ConfirmDialog;
