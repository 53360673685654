import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ValidationError } from './interfaces';
import DialogContent from '@material-ui/core/DialogContent';

export interface IValidationItem {
	type: 'Warning' | 'Error';
	path: string;
	messages: string[];
}

const ValidationResultDialog: React.FC<{
	inputs: ValidationError[];
	actions?: React.ReactNode;
	title?: string;
	onCancel: (result?: any) => void;
}> = ({ title, inputs, onCancel, actions }) => {
	const [items, setItems] = useState([] as IValidationItem[]);

	const refreshItems = () => {
		const currentItems = [].concat(items);
		for (let resultItem of inputs) {
			// Errors
			if (resultItem.constraints) {
				let errorTypes = Object.keys(resultItem.constraints);

				if (errorTypes.length > 0) {
					let errorItem: IValidationItem = {
						path: resultItem.path,
						type: 'Error',
						messages: []
					};

					for (let errorType of errorTypes) {
						errorItem.messages.push(resultItem.constraints[errorType]);
					}

					currentItems.push(errorItem);
				}
			}

			// Warnings
			if (resultItem.warnings) {
				let warningTypes = Object.keys(resultItem.warnings);

				if (warningTypes.length > 0) {
					let warningItem: IValidationItem = {
						path: resultItem.path,
						type: 'Warning',
						messages: []
					};

					for (let warningType of warningTypes) {
						warningItem.messages.push(resultItem.warnings[warningType]);
					}

					currentItems.push(warningItem);
				}
			}
		}

		setItems(currentItems);
	};

	useEffect(() => {
		refreshItems();
	}, [inputs]);

	return <Dialog open={true as any}
		fullWidth={true}
		maxWidth="md"
		fullScreen={true}
		onClose={onCancel}>
		<DialogTitle>{title || "Akce se nezdařila"}</DialogTitle>
		<DialogContent>
			{items.map(item => <div>
				<div key={item.path}>{item.path}</div>
				{item.messages.map((message, messageIndex) => (
					<div key={messageIndex}>{message}</div>
				))}
			</div>)}
		</DialogContent>
		<DialogActions>
			<Button variant="text" onClick={onCancel}>Zrušit</Button>
			{actions && actions}
		</DialogActions>
	</Dialog>
}

export default ValidationResultDialog;
