import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { ModalContext, openConfirm } from './modal';
import { DialogContent, Grid, Typography } from '@material-ui/core';
import { DataState } from './dataState';
import DateTime from './DateTime';
import { httpPost, httpDelete } from './http';
import { TextBox } from './TextBox';
import { IMaskInput } from 'react-imask';
import moment from 'moment';

const ReservationForm: React.FC<{
	onConfirm: () => void;
	onClose?: () => void;
	data?: any;
}> = ({ onConfirm, data }) => {
	const modal = useContext(ModalContext);
	const [dataState] = useState(new DataState(data.id ? data : {}));
	const [record, setRecord] = useState<any>({});

	useEffect(() => {
		const sub = dataState.recordChange
			.subscribe(x => setRecord(x));

		if (!data.id) {
			httpPost(`/p/rm/select`, {
				event: data
			})
				.then(result => {
					console.log(result);
					dataState.setRecord(result.dataState)
				});
		}

		return () => sub.unsubscribe();
	}, []);

	const onCancel = () => modal().close();

	const doConfirm = async () => {
		let response = await httpPost(`/calendars/events`, dataState.record, {
			targetDataState: dataState
		});

		if (response) {
			onConfirm();
			modal().close();
		}
	};

	const doDelete = async () => {
		let r =  await openConfirm('');
		if (r.value) {
			await httpDelete(`/calendars/events/${data.id}?checked=` + r.checked);
			onConfirm();
			modal().close();
		}
	};

	return (
		<React.Fragment>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<Typography variant="h5">Termín rezervace <span style={{ color: '#d60402' }}>{moment(record.dateTimeFrom).format('D.M.YYYY')}</span> od <span style={{ color: '#d60402' }}>{moment(record.dateTimeFrom).format('HH:mm')}</span> do <span style={{ color: '#d60402' }}>{moment(record.dateTimeTo).format('HH:mm')}</span></Typography>
						<br />
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item sm={4}>
						<TextBox label="Zákazník" path="data.clientName" dataState={dataState} />
					</Grid>
					<Grid item sm={4}>
						<TextBox label="Telefon" path="data.clientPhone" dataState={dataState} />
					</Grid>
					<Grid item sm={4}>
						<TextBox label="E-mail" path="data.clientEmail" dataState={dataState} />
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item sm={4}>
						<TextBox label="SPZ" path="data.licencePlate" dataState={dataState} />
					</Grid>
					<Grid item sm={8}>
						<TextBox multiline rows={5} label="Poznámka" path="data.note" dataState={dataState} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				{data?.id && <Button style={{ marginRight: '60px' }} variant="text" color="secondary" onClick={doDelete}>Zrušit rezervaci</Button>}
				<Button variant="contained" size="large" color="primary" onClick={doConfirm}>Potvrdit</Button>
			</DialogActions>
		</React.Fragment>
	)
}

export default ReservationForm;
