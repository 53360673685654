import * as objectPath from 'object-path';
import deepMerge from 'deepmerge';
import { applyPatch, Operation } from 'fast-json-patch';
import { BehaviorSubject, Subject } from 'rxjs';
import { flatten } from 'flat';
import { ValidationError } from './interfaces';
import { deepClone } from 'fast-json-patch/module/core';
import { createContext } from 'react';

export const DataStateContext = createContext(null);

export interface DataPathChange {
	previousValue: any;
	currentValue: any;
	path: string;
	sender?: any;
	containsPath(path: string): boolean;
}

export interface SetEventOptions {
	emitEvent?: boolean;
	emitRecordchangeEvent?: boolean;
	sender?: any;
}

export class DataState<T extends object = any> {
	private _record: T = {} as any;
	private _changed = false;
	private _scopes: any[] = [];
	private _variables: any = {};

	scopePath = '';
	recordChange = new BehaviorSubject<T | null>(null);
	pathChange = new Subject<DataPathChange>();
	validationError = new Subject<ValidationError>();
	validationReset = new Subject<void>();

	get changed() {
		return this._changed;
	}

	get record() {
		if (this.parentDataState) {
			return this.parentDataState.get(this.scopePath) as T;
		} else {
			return this._record as T;
		}
	}

	constructor(defaultValue?: T, private parentDataState?: DataState) {
		if (defaultValue) {
			this.setRecord(defaultValue);
		}
	}

	addVariable(name: string, data: any) {
		this._variables[name] = data;
	}

	getVariables() {
		return this._variables;
	}

	createScope(path: string) {
		let state = new DataState({}, this);
		state.scopePath = path
		return state;
	}

	setRecord(record: any) {
		this._record = record;
		this.recordChange.next(deepClone(record));

		let flatted = flatten<any, any>(record);
		let changedPaths = Object.keys(flatted);

		changedPaths
			.forEach(cp => this.pathChange.next({
				path: cp,
				currentValue: flatted[cp],
				previousValue: null,
				sender: null,
				containsPath: (path: string) => {
					return path == cp;
				}
			}));
	}

	markUnchanged() {
		this._changed = false;
	}

	get(path: string) {
		return objectPath.get(this._record, path);
	}

	patch(operations: Operation[]) {
		this._record = applyPatch(this._record, operations).newDocument;
		this.recordChange.next(this._record);
	}

	getFullPath(scopedPath?: string) {
		return `${this.scopePath ? this.scopePath + '.' : ''}${scopedPath || ''}`;
	}

	mergeWith(data: any) {
		let merged: any = deepMerge(this._record, data);
		this.recordChange.next(merged);
	}

	emitValidationErrors(errors: ValidationError[], reset = true) {
		this.validationReset.next();
		errors.forEach(error => this.validationError.next(error));
	}

	set(path: string, value: any, options: SetEventOptions = {}) {
		let previousValue = this.get(path);

		if (!path) {
			return this.setRecord(value);
		} else {
			objectPath.set(this._record, path, value);
		}

		this.recordChange.next(deepClone(this._record));

		if (options == null || (options && options.emitEvent !== false)) {
			this.pathChange.next({
				previousValue,
				currentValue: value,
				path,
				sender: (options ? options.sender : null),
				containsPath: (pathToCompare: string) => {
					let re = new RegExp(pathToCompare.replace(/\*/gim, `.*`).replace(/\./gim, `\.`) + '$');
					let re2 = new RegExp('^' + pathToCompare.replace(/\*/gim, `.*`).replace(/\./gim, `\.`));

					return re.test(path) || re2.test(path);
				}
			});
		}
	}
}
