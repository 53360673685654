import { Subject, BehaviorSubject } from 'rxjs';
import jwtDecode from 'jwt-decode';

export const Identity$ = new BehaviorSubject<any>(getIdentity());

export function getIdentity() {
	let token = localStorage.getItem('jwt');

	if (token) {
		let tokenData: any = jwtDecode(token);
		return tokenData.identity;
	} else {
		return null;
	}
}

export function refreshIdentityFromToken() {
	Identity$.next(getIdentity());
}
