import axios from 'axios';
import qs from 'qs';
import { handleServerInvalidResponse, handleServerResponse, WARNING_CONFIRMATION, setGlobalLoading } from './app.srv';
import { DataState } from './dataState';

export const API_URL = 'https://reserve-api.tirelogistics.cz';

export interface HttpRequestOptions {
	query?: any;
	targetDataState?: DataState;
}

export async function httpGet<T = any>(url: string, query?: any, options: HttpRequestOptions = {}) {
	try {
		// setGlobalLoading(true);
		let result = await axios.get(`${API_URL}${url}`, {
			params: query,
			paramsSerializer: q => qs.stringify(q, {
				skipNulls: true,
				indices: true,
				encode: false
			}),
			headers: getDefaultHeaders()
		});

		// setGlobalLoading(false);
		return result.data as T;
	} catch (error) {
		handleHttpError(error, options || {});
	}
}

export async function httpDelete(url: string): Promise<void> {
	let headers: any = {};
	let token = localStorage.getItem('jwt');

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	try {
		let result = await axios.delete(`${API_URL}${url}`, {
			headers: headers
		});
	} catch (error) {
		let handledResult = await handleHttpError(error, {});
	}
}

export async function httpPost<T = any>(url: string, body?: any, options: HttpRequestOptions = {}): Promise<T> {
	let headers: any = {};
	let token = localStorage.getItem('jwt');

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	try {
		let result = await axios.post(`${API_URL}${url}`, body, {
			params: options.query,
			headers: headers
		});

		return result.data as any as T;
	} catch (error) {
		let handledResult = await handleHttpError(error, options || {});

		// TODO: Typed
		if (handledResult?.handle) {
			handledResult.handle.close$
				.subscribe(result => {
					if (result == WARNING_CONFIRMATION) {
						httpPost<T>(url, body, {
							query: {
								$confirmation: true
							}
						});
					}
				});
		} else {
			return null;
		}

		// // TODO: Network error
		// throw new HttpError(error.message, error.response.status, error.response.data);
	}
}

export function modelQueryUrl<T>(url: string, query: any) {
	return `${url}?${qs.stringify(query, {
		skipNulls: true,
		indices: true,
		encode: false
	})}`;
}

export function queryUrl(url: string, query: any) {
	return `${url}?${qs.stringify(query, {
		skipNulls: true,
		indices: true,
		encode: false
	})}`;
}

export class HttpError extends Error {
	constructor(message: string, public status: number, public serverError: any) {
		super(message);
	}
}

function handleHttpError(error: any, options: HttpRequestOptions) {
	return handleServerInvalidResponse(error, options);

	// throw new HttpError(error.message, error.response.status, error.response.data);
}

function getDefaultHeaders() {
	let headers: any = {};
	let token = localStorage.getItem('jwt');

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	return headers;
}
