import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogTitle, DialogContent, DialogActions, Button, DialogProps, Typography, IconButton, Icon, makeStyles, Theme, createStyles } from '@material-ui/core';
import { ModalContext, IModalHandler } from './modal';

export interface SimpleDialogProps {
	title?: string
	fullHeight?: boolean;
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeIcon: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		fullHeightPaper: (props: SimpleDialogProps) => ({
			height: (props.fullHeight ? '100vh' : null)
		}),
		fullHeightContent: (props: SimpleDialogProps) => ({
			padding: (props.fullHeight ? 0 : undefined)
		})
	}),
);

const SimpleDialog: React.FC<SimpleDialogProps> = (props) => {
	const classes = useStyles(props);
	const handler = useContext(ModalContext);

	const maxWidth = props.maxWidth || 'md';

	return <Dialog open={true as any}
		fullWidth={true}
		classes={{
			paper: classes.fullHeightPaper
		}}
		maxWidth={maxWidth}
		onClose={() => handler().close()}>
		<DialogTitle>
			{props.title}
			<IconButton classes={{ root: classes.closeIcon }} onClick={() => handler().close()}>
				<Icon>close</Icon>
			</IconButton>
		</DialogTitle>
		<DialogContent classes={{
			root: classes.fullHeightContent
		}}>
			{props.children}
		</DialogContent>
		<DialogActions>

		</DialogActions>
	</Dialog>
}

export default SimpleDialog;
