import { getTheme, ApplicationContext } from '@datr-browser/components';
import { AppBar, ThemeProvider, Typography, Container } from '@material-ui/core';
import 'moment/locale/cs';
import React, { useState, useContext, useEffect } from 'react';
import { OptionsInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid';
import localeCs from '@fullcalendar/core/locales/cs';
import { httpGet, httpPost } from './http';
import { CalendarEvent } from './models';
import { openConfirm, openDialog } from './modal';
import ReservationForm from './ReservationForm';
import LoginPage from './LoginPage';

function App() {
	const theme = getTheme();
	theme.palette.primary.main = '#d60402';
	theme.palette.secondary.main = '#333';

	const [events, setEvents] = useState([]);
	const context = useContext(ApplicationContext);

	const refreshEvents = async () => {
		let loadedEvens = await httpGet<CalendarEvent[]>(`/calendars/events`, {
			$count: 300
		})

		setEvents(loadedEvens
			.map(item => _itemToEvent(item)));
	};

	const _itemToEvent = (item: CalendarEvent) => {
		return {
			start: item.dateTimeFrom,
			end: item.dateTimeTo,
			title: `${item.data.clientName} (${item.data.clientPhone})`,
			extendedProps: item
		};
	}

	const onEventClick = async (data: any) => {
		let itemData = {
			...data.event.extendedProps,
			$item: data.event.extendedProps
		};

		console.log(data.event.extendedProps);
		openDialog('Rezervace', (
			<ReservationForm data={data.event.extendedProps} onConfirm={() => refreshEvents()} />
		), theme)
	}

	const _toSimpleEvent = (event: any) => {
		return {
			start: event.start,
			end: event.end
		};
	}

	const onEventDrop = async (data: any) => {
		let r = await openConfirm('Opravdu chcete změnit rezervaci?');
		if (r.value == false) {
			data.revert();
			return;
		}

		try {
			let response: any = await httpPost(`/p/rm/reschedule?checked=` + r.checked, {
				item: data.event.extendedProps,
				previousEvent: _toSimpleEvent(data.oldEvent),
				event: _toSimpleEvent(data.event),
				delta: data.delta
			});
		} catch (error) {
			data.revert();
		}
	}

	const onEventResize = async (data: any) => {
		let r = await openConfirm('Opravdu chcete změnit rezervaci?');
		if (r.value == false) {
			data.revert();
			return;
		}

		try {
			let response: any = await httpPost(`/p/rm/reschedule?checked=` + r.checked, {
				item: data.event.extendedProps,
				previousEvent: _toSimpleEvent(data.prevEvent),
				event: _toSimpleEvent(data.event),
				delta: data.endDelta
			});
		} catch (error) {
			data.revert();
		}
	}

	const onSelect = async (data: any) => {
		try {
			openDialog('Rezervace', (
				<ReservationForm data={{
					start: data.start,
					end: data.end
				}} onConfirm={() => refreshEvents()} />
			), theme);
		} catch (error) {

		}
	}

	useEffect(() => {
		refreshEvents()
		const interval = setInterval(() => {
			refreshEvents();
		}, 1000 * 60 * 1);

		return () => clearInterval(interval);
	}, []);

	let options: OptionsInput = {
		editable: true,
		eventClick: x => { onEventClick(x) },
		eventDrop: onEventDrop,
		eventResize: onEventResize,
		selectable: true,
		allDaySlot: false,
		selectMirror: true,
		select: onSelect,
		minTime: '05:00:00',
		maxTime: '20:00:00',
		slotDuration: '00:10:00',
		locales: [localeCs],
		locale: 'cs'
	};

	if (localStorage.getItem('jwt') == null) {
		return <ThemeProvider theme={theme}>
			<AppBar position="static">
				<Typography variant="h6">
					<img src="/logo.png" style={{ margin: '0 20px' }} />
				</Typography>
			</AppBar>
			<LoginPage />
		</ThemeProvider>;
	}

	return (
		<ThemeProvider theme={theme}>
			<AppBar position="static">
				<Typography variant="h6">
					<img src="/logo.png" style={{ margin: '0 20px' }} />
				</Typography>
			</AppBar>
			<div style={{ margin: '20px' }}>
				<FullCalendar
					{...options}
					events={events}
					defaultView="timeGridWeek"
					plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]} />
			</div>
		</ThemeProvider>
	);
}

export default App;
