
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextFieldProps, TextField } from '@material-ui/core';
import { dataStated } from './dataStateHelper';

const TextBoxBase: React.FC<TextFieldProps> = (props) => {
	return <TextField
		variant="outlined"
		fullWidth={true}
		InputLabelProps={{
			shrink: true
		}} {...props}>
		{props.children}
	</TextField>
}

export const TextBox = dataStated(TextBoxBase);
