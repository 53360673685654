import React from 'react';
import { Subject, BehaviorSubject } from 'rxjs';
import { ValidationError } from './interfaces';
import { openModal, openAlert } from './modal';
import ValidationResultDialog from './ValidationResultDialog';
import Button from '@material-ui/core/Button';
import { HttpRequestOptions } from './http';

export const CANCEL = Symbol();
export const WARNING_CONFIRMATION = Symbol();

export const PageMenu$ = new BehaviorSubject<IPageMenuItem[]>(null);
export const ApplicationTitle$ = new BehaviorSubject<string>(null);
export const GlobalLoading$ = new Subject<boolean>();
export const RefreshModel$ = new Subject<{ name: string, id?: number }>();

export function setApplicationTitle(title: string) {
	ApplicationTitle$.next(title);
}

export function setGlobalLoading(state: boolean) {
	GlobalLoading$.next(state);
}

export function setPageMenu(items: IPageMenuItem[]) {
	PageMenu$.next(items);
}

export interface IPageMenuItem {
	label: string;
	icon?: string;
	onClick: () => void;
}

export interface IHandleServerResponse {

}

export function handleServerResponse(response: any) {
	if (response.navigateTo) {

	}
}

export function handleServerInvalidResponse(errorResponse: any, options: HttpRequestOptions) {
	console.log(options);
	if (errorResponse.response?.status == 400) {
		return handleInvalidValidationResult(errorResponse.response.data.errors, options);
	} else {
		// TODO
		openAlert('Neočekávaná chyba')
		console.log(errorResponse);
		throw errorResponse;
	}
}

export async function handleInvalidValidationResult(validationErrors: ValidationError[], options: HttpRequestOptions) {
	let errors = validationErrors
		.filter(ve => ve.constraints != null);

	let warnings = validationErrors
		.filter(ve => ve.warnings != null);

	if (options.targetDataState) {
		let registered = errors
			.filter(error => error.path != null);

		errors = errors
			.filter(error => error.path == null);

			console.log('emit', registered)
		options.targetDataState.emitValidationErrors(registered);
	}

	if (warnings.length > 0 && errors.length == 0) {
		const handle = openModal(<ValidationResultDialog
			title="Upozornění"
			inputs={validationErrors}
			actions={<React.Fragment>
				<Button variant="outlined" color="secondary" onClick={() => handle.close(WARNING_CONFIRMATION)}>Pokračovat přes upozornění</Button>
			</React.Fragment>}
			onCancel={() => handle.close()}
		/>);

		return {
			handle,
			hasWarnings: warnings.length > 0,
			hasErrors: errors.length > 0
		};
	} else if (errors.length > 0) {
		const handle = openModal(<ValidationResultDialog
			title="Akce není povolena"
			inputs={validationErrors}
			onCancel={() => handle.close()}
		/>);

		return {
			handle,
			hasWarnings: warnings.length > 0,
			hasErrors: errors.length > 0
		};
	}
}
