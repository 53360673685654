import { Theme, ThemeProvider, Snackbar } from '@material-ui/core';
import React, { createContext } from "react";
import ReactDOM from "react-dom";
import { Subject } from 'rxjs';
import AlertDialog from "./AlertDialog";
import ConfirmDialog from './ConfirmDialog';
import Alert, { Color } from '@material-ui/lab/Alert';
import SimpleDialog from './SimpleDialog';

export const ModalContext = createContext(null as (() => IModalHandler<any>));

export interface IModalHandler<T> {
	component: T;
	close$: Subject<any>;
	close(result?: any): void;
};

export function openModal<T extends JSX.Element>(component: T, theme?: Theme) {
	let el = document.createElement('div');

	document.body.appendChild(el);
	ReactDOM.render((
		<ThemeProvider theme={theme}>
			<ModalContext.Provider value={() => handler}>
				{component}
			</ModalContext.Provider>
		</ThemeProvider>
	), el);

	let handler: IModalHandler<T> = {
		component,
		close$: new Subject<any>(),
		close: (result?: any) => {
			handler.close$.next(result);
			ReactDOM.unmountComponentAtNode(el);
		}
	};

	return handler;
}

export function openSnackbar(severity: Color, message: string) {
	const handle = openModal((
		<Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={() => handle.close()}>
			<Alert onClose={() => handle.close()} severity={severity}>{message}</Alert>
		</Snackbar>
	));

	return handle;
}

export function openAlert(message: string) {
	return new Promise<boolean>((resolve, reject) => {
		let ref = openModal(<AlertDialog message={message} onCancel={() => done()}></AlertDialog>);

		const done = () => {
			resolve();
			ref.close();
		};
	});
}

export function openConfirm(message: string) {
	return new Promise<{value: boolean, checked: boolean}>((resolve, reject) => {
		let ref = openModal(<ConfirmDialog onCancel={() => done(false)} onConfirm={checked => done({
			value: true,
			checked: checked
		})}></ConfirmDialog>);

		const done = (opts) => {
			resolve(opts);
			ref.close();
		};
	});
}

export function openDialog(title: string, component: React.ReactElement, theme: Theme) {
	openModal((
		<SimpleDialog title={title}>
			{component}
		</SimpleDialog>
	), theme)
}
